.PageNotFound {
    margin-top: 5%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.PageNotFound-text {
    font-size: 32px;
    font-weight: 300;
    margin-bottom: 50px;
}

.PageNotFound iframe {
    width: 420px;
    height: 315px;
}