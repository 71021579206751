.ProjectPreview {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 40px;
    margin-left: 30px;
    width: calc(100% - 30px);
    opacity: 0;
    transition: opacity 1s ease-in;
    text-decoration: none;
    color: white;
}

.ProjectPreview a {
    text-decoration: inherit;
    color: inherit;
}

.ProjectPreview-link {
    font-size: 18px;
    font-weight: 400;
}

.ProjectPreview-link::after {
    content: "->";
    margin-left: 5px;
    font-weight: 600;
    transition: margin-left 100ms ease;
}

.ProjectPreview:hover .ProjectPreview-link::after {
    margin-left: 10px;
}

.ProjectPreview-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 10px;
}

.ProjectPreview-trophy {
    height: 16px;
    display: inline;
    margin-left: 8px;
}

.ProjectPreview-new {
    height: 16px;
    display: inline;
    margin-left: 8px;
}

.ProjectPreview-description {
    font-size: 16px;
    line-height: 28px;
    font-weight: 300;
    width: 375px;
}

.ProjectPreview-description p {
    margin-top: 0;
    margin-bottom: 10px;
}

.ProjectPreview-thumbnail {
    border: 2px solid white;
    border-radius: 10px;
    margin-right: 35px;
    width: 200px;
    height: 160px;
    min-width: 200px;
    min-height: 160px;
}

@media (max-width: 600px) {
    .ProjectPreview {
        flex-direction: column;
        align-items: center;
        text-align: center;
        max-width: 90%;
        margin-left: 0;
    }
    .ProjectPreview-title {
        order: 1;
        font-weight: 400;
        margin-bottom: 5px;
        justify-content: center;
    }
    .ProjectPreview-thumbnail {
        order: 3;
        width: inherit;
        height: inherit;
        max-width: 285px;
        margin-right: 0;
    }
    .ProjectPreview-link {
        display: none;
    }
    .ProjectPreview-description {
        order: 2;
        font-size: 15px;
        max-width: 285px;
        font-weight: 400;
    }
    .ProjectPreview-description>p:not(:first-child) {
        display: none;
    }
    .ProjectPreview-info-container {
        display: flex;
        flex-direction: column;
    }
}