.App {
  font-family: 'niveau-grotesk', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 65px;
  color: #eee;
  transition: opacity 700ms ease-in;
}

.App-banner {
  background-color: #353C47;
  margin-top: -65px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-left: 0;
  margin-right: 0;
}

.App-banner>img {
  height: 38px;
}

.App-banner strong {
  font-weight: 600;
}

.App-banner-text {
  max-width: 502px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  margin-left: 18px;
}

@media (max-width: 600px) {
  .App {
    padding-top: 50px;
    padding-bottom: 25px;
  }
  .App-banner {
    padding-top: 40px;
    padding-bottom: 25px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
  }
  .App-banner-text {
    max-width: 80%;
  }
}