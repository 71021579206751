.Header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Header-name {
    margin-bottom: 16px;
    font-size: 42px;
    font-weight: 200;
}

.Header-name a {
    text-decoration: none;
    color: inherit;
}

.Header-links {
    font-size: 26px;
    margin-bottom: 30px;
    will-change: transform;
}

.Header-links a {
    color: inherit;
    font-weight: 200;
    padding-left: 10px;
    padding-right: 10px;
    text-decoration: none;
    transition: opacity 400ms ease;
}

.Header-links:hover a {
    opacity: 0.65;
}

.Header-links:hover a:hover {
    opacity: 1;
}

.Header-links a.active {
    font-weight: 500;
}

@media (min-width: 970px) {
    .Header.compact {
        position: fixed;
        top: 28px;
        line-height: 46px;
        left: 25px;
    }
    .Header.compact .Header-name {
        display: none;
    }
    .Header.compact .Header-links {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-size: 28px;
    }
}