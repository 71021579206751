.ProjectWriteup {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 15%;
    padding-right: 15%;
    max-width: 650px;
    margin: auto;
}

.ProjectWriteup>div {
    opacity: 0;
    transition-property: opacity;
    transition-duration: 1.5s;
    transition-timing-function: ease-in;
}

.ProjectWriteup video {
    margin-top: 20px;
    margin-bottom: 30px;
}

.ProjectWriteup-title {
    font-size: 34px;
    font-weight: 300;
    margin-top: 30px;
    margin-bottom: 10px;
}

@media (min-width: 970px) {
    .ProjectWriteup-title {
        font-size: 36px;
    }
}

.ProjectWriteup-embedContainer {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 100%;
}

.ProjectWriteup-embedContainer iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.ProjectWriteup a {
    color: inherit;
    font-weight: 500;
}

.ProjectWriteup-summary a:hover {
    border-bottom: 1px solid white;
}

.ProjectWriteup-subtitle {
    font-size: 28px;
    margin-top: 20px;
}

.ProjectWriteup-summary {
    font-size: 20px;
    font-weight: 300;
    margin-top: 25px;
    margin-bottom: 5px;
    line-height: 32px;
}

.ProjectWriteup-summary a {
    text-decoration: none;
}

.ProjectWriteup-summary-icon {
    height: 20px;
    margin-right: 10px;
    margin-top: 2px;
    margin-bottom: -2.5px;
}

.ProjectWriteup-content {
    line-height: 180%;
    font-size: 18px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
}

.ProjectWriteup-bottomNav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 30px;
    margin-left: -20px;
    margin-right: -20px;
}

.ProjectWriteup-bottomNav a {
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
}

/* If no previous project, align to right */

.ProjectWriteup-forward {
    margin-left: auto;
}

@media (max-width: 600px) {
    .ProjectWriteup {
        padding-left: 6%;
        padding-right: 6%;
    }
}