.Attributions {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 50px;
    line-height: 30px;
    opacity: 0.9;
}

.Attributions-container {
    max-width: 340px;
}

.Attributions-subtitle {
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 20px;
}

.Attribution {
    font-size: 18px;
    font-weight: 300;
    font-size: 18px;
}

.Attribution-name {
    font-weight: 300;
}

.Attribution-author {
    font-weight: 500;
}

.Attributions-text {
    margin-top: 20px;
    margin-bottom: 20px;
}

.Attributions a {
    font-weight: 500;
    opacity: 1;
    color: inherit;
    text-decoration: none;
}