.Gallery {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
}

.Gallery-caption {
    font-weight: 300;
    text-align: center;
}

.Gallery-imageBorder {
    border: 1px solid #eee;
    box-sizing: border-box;
}