.Footer {
    font-weight: 300;
    width: 100%;
    position: relative;
}

.Footer-backToTop {
    position: fixed;
    left: 40px;
    top: 40px;
    opacity: 0.8;
    transition: opacity 500ms ease-in-out;
    opacity: 0;
}

.Footer-backToTop>img {
    height: 40px;
    width: 40px;
}

.Footer-backToTop:hover {
    opacity: 1;
    cursor: pointer;
}

.Footer-info {
    position: absolute;
    right: 18px;
    bottom: 0px;
    opacity: 0.8;
    font-size: 18px;
}

.Footer-info {
    opacity: 0.8;
    transition: opacity 200ms ease-out;
    color: inherit;
    text-decoration: none;
}

.Footer-info>img {
    margin-right: 10px;
    margin-bottom: -4.5px;
    height: 20px;
}

.Footer-info:hover {
    opacity: 1;
    cursor: pointer;
}

@media (max-width: 850px) {
    .Footer-backToTop {
        display: none;
    }
}

@media (max-width: 600px) {
    .Footer {
        margin-top: 25px;
    }
    .Footer-info {
        width: 100%;
        display: flex;
        justify-content: center;
        right: 0;
    }
    .Footer-info>img {
        display: block;
        margin-right: 10px;
    }
}